import React from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";

import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";
import astronaut from "../assets/Images/spaceman.png";

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;
const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }

`;
const Spaceman = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  width: 20vw;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
  }
`;
const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6rem + 1vw);
  backdrop-filter: blur(4px);

  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  font-family: "Ubuntu Mono", monospace;
  font-style: italic;
`;

const AboutPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme='dark' />
        <SocialIcons theme='dark' />
        <PowerButton />
        <ParticleComponent theme='dark' />

        <Spaceman>
          <img src={astronaut} alt='spaceman' />
        </Spaceman>
        <Main>
          Jeg er en Fullstack Utvikler med base i vakre Valdres i Norge. Jeg
          setter brukeropplevelsen først, og designer nettsider med det i fokus.
          <br /> <br />
          Interessen min for utvikling spenner seg over hele stacken, og jeg
          elsker å utfordre meg i å benytte nye rammeverk i nye prosjekter. Jeg
          er freelancer og jobber ut fra mine kontorer på Fagernes i Valdres.
          <br /> <br />
          All teknologi kan være en kunst, så lenge du legger samvittigheten din
          på bordet.
          <br />
          <br />
          Ta kontakt enten via linken på forsiden eller via SoMe-linken til
          venstre
        </Main>

        <BigTitle text='ABOUT' top='10%' left='5%' />
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
